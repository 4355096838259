<template>
  <BaseDialog
    :title="displayData.dialogTitle"
    :btn1="displayData.btnText.confirm"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <section>
      <BaseElForm ref="formRef" :model="formData" label-position="top" :rules="formRules">
        <BaseElFormItem :label="displayData.formItemLabel.name" prop="name">
          <BaseElInput v-model="formData.name" :placeholder="displayData.placeholder.name" />
        </BaseElFormItem>

        <BaseElFormItem
          v-if="showControl.formItem.useBenefit"
          :label="displayData.formItemLabel.useBenefit"
          prop="useBenefit"
        >
          <BaseElRadioGroup v-model="formData.useBenefit" @change="onUseBenefitChange">
            <BaseElRadio v-for="option in displaySpendRecordOptions" :key="option.value" :label="option.value">
              {{ option.label }}
            </BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem v-if="showControl.formItem.coupon" :label="displayData.formItemLabel.coupon" prop="coupon">
          <BaseElSelect
            v-model="formData.coupon"
            popper-class="select-popper"
            @visible-change="modal.selector.coupon = true"
          >
            <BaseElSelectOption
              v-for="resource in getResouceName(formData.coupon, 'coupon') || []"
              :key="resource.value"
              :label="resource.label"
              :value="resource.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem :label="displayData.formItemLabel.type" prop="type">
          <BaseElSelect v-model="formData.type" @change="onDiscountTypeChange">
            <BaseElSelectOption
              v-for="item in displayDiscountTypeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type === 'cash'" :label="displayData.formItemLabel.cashAmount" prop="cashAmount">
          <BaseElInput v-model="formData.cashAmount" :placeholder="displayData.placeholder.cashAmount" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'percent'"
          :label="displayData.formItemLabel.percentAmount"
          prop="percentAmount"
        >
          <BaseElInputNumber v-model="formData.percentAmount" :precision="2" :step="0.01" :min="0.01" :max="1" />
          <div style="color: gray; font-size: 14px">{{ displayData.hint.percentAmount }}</div>
        </BaseElFormItem>

        <BaseElFormItem :label="displayData.formItemLabel.order" prop="order">
          <BaseElInput v-model="formData.order" :placeholder="displayData.formItemLabel.order" />
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <CouponAdvanceSelect
      v-if="modal.selector.coupon"
      :data="displayCouponOptions"
      disabledExp
      :typeOptions="couponTypeOptions"
      @close="modal.selector.coupon = false"
      @confirm="onSelectResource($event, 'coupon')"
    />
  </BaseDialog>
</template>

<script>
import { GetCoupon } from '@/api/lottery/coupon'
import { CreateSalesDiscount, UpdateSalesDiscount, FindSpendSettingCoupon } from '@/api/sales'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import notifyMessage from '@/config/notifyMessage'
import { useFetch } from '@/use/fetch'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, lengthRules, noEmptyRules, rangeRules } from '@/validation'
import { omit, map, find, first, filter, get } from 'lodash'

import { couponTypeConfig } from '@/config/couponExchange'

import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
export default defineComponent({
  name: 'EditSalesDiscountModal',
  components: {
    BaseDialog,
    CouponAdvanceSelect,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    const { checkAction } = usePermissions()
    const { fetchAll } = useFetch()
    initFormData({
      name: '',
      type: 'cash',
      cashAmount: '',
      percentAmount: '',
      order: 100,
      useBenefit: 'none',
      coupon: null,
    })
    const modal = reactive({
      selector: {
        coupon: false,
      },
    })

    const resourceData = reactive({
      coupon: [],
      // classTicket: [],
      // punchCard: [],
      // pointCard: [],
    })
    const couponInvalidList = ref([])

    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))
    const usePospalCoupon = computed(() => checkAction('admin.couponPospal.find'))
    const useOpentixCoupon = computed(() => checkAction('admin.couponOpentix.page'))
    const couponTypeOptions = computed(() => {
      const omitList = []
      if (!useCoupon.value) omitList.push('coupon')
      if (!useExchangeCoupon.value) omitList.push('exchange')
      if (!usePospalCoupon.value) omitList.push('pospal')
      if (!useOpentixCoupon.value) omitList.push('opentix')
      return omit(couponTypeConfig, omitList)
    })

    const useFeatures = computed(() => {
      return {
        coupon: checkAction('admin.coupon.page'),
        // classTicket: checkAction('admin.classTicket.page'),
        // punchCard: checkAction('admin.punchCard.page'),
        // pointCard: checkAction('admin.pointCard.page'),
      }
    })

    const displayData = computed(() => {
      const data = {
        dialogTitle: '新增銷售優惠',
        formItemLabel: {
          name: '名稱',
          type: '折扣形式',
          cashAmount: '金額',
          percentAmount: '百分比',
          order: '排序',
          useBenefit: '是否需使用權益折抵',
          coupon: '票券綁定',
        },
        btnText: {
          cancel: '取消',
          confirm: '新增',
        },
        errorMsg: {
          name: '請輸入優惠名稱',
          cashAmount: '請輸入金額',
          percentAmount: '請輸入百分比',
          order: '請輸入排序',
          useBenefit: '請選擇是否需使用權益折抵',
          coupon: '請選擇票券',
          type: '請選擇打折形式',
        },
        placeholder: {
          name: '請輸入名稱',
          cashAmount: '請輸入金額',
          percentAmount: '請輸入百分比',
          order: '請輸入排序',
        },
        hint: {
          percentAmount: '範例：打9折請填寫0.90，65折請填寫0.65 (最小為0.01)',
        },
      }

      if (isEdit.value) {
        data.btnText.confirm = '儲存'
      }

      return data
    })

    const showControl = computed(() => {
      const controls = {
        formItem: {
          coupon: false,
          useBenefit: false,
        },
      }
      if (checkAction('adminView.salesPos.enabled')) {
        controls.formItem.useBenefit = true
        if (formData.useBenefit === 'coupon') {
          controls.formItem.coupon = true
        }
      }
      return controls
    })
    const formRules = computed(() => {
      const rules = {
        name: noEmptyRules(displayData.value.errorMsg.name),
        order: [isDigitRules(), noEmptyRules(displayData.value.errorMsg.order), rangeRules()],
        cashAmount: [
          isDigitRules(),
          noEmptyRules(displayData.value.errorMsg.cashAmount),
          rangeRules(),
        ],
        percentAmount: [
          noEmptyRules(displayData.value.errorMsg.percentAmount),
          isDigitRules(),
          lengthRules(1, 2),
        ],
        useBenefit: [noEmptyRules(displayData.value.errorMsg.useBenefit)],
        type: [noEmptyRules(displayData.value.errorMsg.type)],
      }

      if (formData.useBenefit === 'coupon') {
        rules.coupon = [noEmptyRules(displayData.value.errorMsg.coupon)]
      }

      return rules
    })

    const isEdit = computed(() => Boolean(get(props.selectRow, 'id')))

    const displaySpendRecordOptions = computed(() => {
      const options = [
        { label: '無', value: 'none' },
        { label: '票券', value: 'coupon' },
      ]
      let omitList = []

      const mustSpendSettingType = get(props.selectRow, 'mustSpendSetting.type', 'none')

      if (!isEdit.value) {
        if (!useFeatures.value.coupon) {
          omitList.push('coupon')
        }
      }

      if (isEdit.value) {
        if (mustSpendSettingType === 'coupon') {
          omitList = filter(options, (item) => item !== 'coupon')
        }
      }

      return filter(options, (item) => !omitList.includes(item.value))
    })

    // ?? 顯示的打折形式選項
    const displayDiscountTypeOptions = computed(() => {
      const options = [
        { id: 'cash', name: '金額' },
        { id: 'percent', name: '百分比' },
        { id: 'free', name: '自定義金額' },
      ]

      const omitList = []

      // 選擇＂票券＂時，沒有自定義金額的折扣形式
      if (formData.useBenefit === 'coupon') {
        omitList.push('free')
      }

      return filter(options, (item) => !omitList.includes(item.id))
    })

    const submitPayload = computed(() => {
      const payload = {
        shopId: shopId.value,
        id: get(props.selectRow, 'id'),
        name: formData.name,
        type: formData.type,
        cashAmount: formData.type === 'cash' ? +formData.cashAmount : undefined,
        percentAmount: formData.type === 'percent' ? +formData.percentAmount : undefined,
        order: formData.order,
      }

      if (formData.useBenefit !== 'none') {
        if (formData.useBenefit === 'coupon') {
          payload.mustSpendSetting = {
            type: formData.useBenefit,
            couponId: formData.coupon,
          }
        }
      }

      if (!isEdit.value) {
        delete payload.id
      }

      return payload
    })

    const getResouceName = computed(() => {
      return (data, type) => {
        const exist = find(resourceData[type], { id: data })
        return exist ? [{ label: exist.name, value: exist.id }] : []
      }
    })

    // => 當打折形式改變時
    const onDiscountTypeChange = (newType) => {
      formData.cashAmount = ''
      formData.percentAmount = ''
    }

    // => 當使用權益折抵改變時
    const onUseBenefitChange = (value) => {
      formData.coupon = null
      formData.type = null
    }

    // => 當按下確認
    const onConfirm = async () => {
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      let apiMethod = CreateSalesDiscount
      if (isEdit.value) apiMethod = UpdateSalesDiscount

      const [res, err] = await apiMethod(submitPayload.value)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      let msg = notifyMessage.createSuccess
      if (isEdit.value) msg = notifyMessage.updateSuccess
      window.$message.success(msg)

      emit('refresh')
      emit('close')
    }

    // => 同步表單資料
    const syncFormData = () => {
      const data = props.selectRow

      formData.name = data.name
      formData.type = data.type
      formData.order = data.order
      formData.cashAmount = data.cashAmount
      formData.percentAmount = data.percentAmount
      formData.useBenefit = get(data, 'mustSpendSetting.type', 'none')
      formData.coupon = get(data, 'mustSpendSetting.couponId', null)
    }

    const getResouceData = async () => {
      const callList = []
      if (useFeatures.value.coupon) {
        callList.push(async () => {
          await fetchAll(GetCoupon, { shopId: shopId.value, type: 'coupon' }, (res) => {
            resourceData.coupon = res
          })
        })
      }

      await Promise.all(callList.map((call) => call()))
    }

    const onSelectResource = (data, type) => {
      formData[type] = first(data)
      modal.selector.coupon = false
    }

    const findSpendSettingCoupon = async () => {
      const [res, err] = await FindSpendSettingCoupon({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
      }
      couponInvalidList.value = filter(res, { valid: false })
    }

    const displayCouponOptions = computed(() => {
      return map(resourceData.coupon, (item) => {
        const isInvalid = find(couponInvalidList.value, { Coupon: { id: item.id } })
        return {
          ...item,
          isInvalid: Boolean(isInvalid),
          invalidReasonCode: get(isInvalid, 'validReasonCode'),
        }
      })
    })

    onMounted(() => {
      if (isEdit.value) syncFormData()
      getResouceData()
      findSpendSettingCoupon()
    })

    return {
      modal,
      loading,
      formRef,
      formData,
      onConfirm,
      displayData,
      formRules,
      displayDiscountTypeOptions,
      onDiscountTypeChange,
      resourceData,
      showControl,
      onSelectResource,
      getResouceName,
      onUseBenefitChange,
      submitPayload,
      displaySpendRecordOptions,
      couponInvalidList,
      displayCouponOptions,
      couponTypeOptions,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
